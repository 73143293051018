import React from 'react';
import Helmet from "react-helmet";
import ListOptions from './listOptions';

function PaymentMethods() {

	const richData =
	{
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"name": "Payment methods at SWISSMADE.HOST",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What payment methods does Swiss Made Host accept?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Swiss Made Host accepts a variety of payment methods, including PayPal (PYPL), VISA (V), MasterCard (MA), Discover (DFS), American Express (AXP), Bank Wire (BW), SEPA, SWIFT, QIWI, WebMoney (WM) and cryptocurrency payments."
				}
			},
			{
				"@type": "Question",
				"name": "Can I pay with my credit or debit card on Swiss Made Host?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Yes, Swiss Made Host accepts all major credit and debit cards, including VISA, MasterCard, and American Express."
				}
			},
			{
				"@type": "Question",
				"name": "Does Swiss Made Host accept PayPal?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Yes, Swiss Made Host accepts PayPal as a payment method."
				}
			},
			{
				"@type": "Question",
				"name": "Can I pay by bank transfer on Swiss Made Host?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Yes, Swiss Made Host accepts bank wire transfers, SEPA transfers, and SWIFT transfers."
				}
			},
			{
				"@type": "Question",
				"name": "What is QIWI and can I use it to pay on Swiss Made Host?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "QIWI is a payment service provider that allows for easy and secure payments. Yes, Swiss Made Host accepts QIWI as a payment method."
				}
			},
			{
				"@type": "Question",
				"name": "Which cryptocurrencies are accepted at Swiss Made Host?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Swiss Made Host accepts all most popular cryptocurrencies and tokens for various web hosting products (VPS, Dedicated Servers, Cloud hosting, DDoS protection, VPN etc), here's just some of them: Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB), Tether (USDT), Litecoin (LTC), TRON (TRX), Nextcoin (NXT), Bitcoin Cash (BCH), Tether (TRC-20) (USDT), Tether (ERC-20) (USDT), Gemini Dollar (GUSD), Ripple (XRP), Dogecoin (DOGE), Nano (NANO), Monero (XMR), DASH (DASH), VeChain (VET), Uniswap (UNI), Solana (SOL), Cardano (ADA), Shiba Inu (SHIB), DigiByte (DGB), TrueUSD (TUSD), Litecoin Cash (LCC), Chainlink (LINK), Polygon (MATIC), Harmony (ONE), Theta Network (THETA), Decentraland (MANA), WazirX (WRX)."
				}
			},
			{
				"@type": "Question",
				"name": "How do I pay with cryptocurrency at Swiss Made Host?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "To pay with cryptocurrency at Swiss Made Host, choose your desired web hosting product and proceed to checkout. At the payment step, select the cryptocurrency option and choose your preferred coin from the list of accepted cryptocurrencies. Send the required amount of cryptocurrency to the provided wallet address, and your payment will be processed."
				}
			},
			{
				"@type": "Question",
				"name": "Are there any additional fees for paying with cryptocurrency?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Swiss Made Host does not charge any additional fees for payments made with cryptocurrencies. However, you may need to pay network transaction fees for the cryptocurrency you choose. These fees are determined by the respective blockchain networks and are not controlled by Swiss Made Host."
				}
			},
			{
				"@type": "Question",
				"name": "How long does it take for my cryptocurrency payment to be processed?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Cryptocurrency payment processing times may vary depending on the coin you choose and the network congestion at the time of the transaction. Generally, most cryptocurrency payments are processed within a few minutes to an hour. However, during peak times or for cryptocurrencies with slower confirmation times, it may take longer for your payment to be processed and confirmed."
				}
			},
			{
				"@type": "Question",
				"name": "Can I get a refund if I paid with cryptocurrency?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Refund policies may vary depending on the web hosting product and the terms and conditions of Swiss Made Host. If a refund is approved for a cryptocurrency payment, it will be credited to your account balance rather than being returned in the original cryptocurrency. It is essential to check the refund policy of Swiss Made Host before making a payment with cryptocurrency."
				}
			}
		]
	}

	return (

		<React.Fragment>
			<Helmet>
				<title>Accepted Payment Methods | Swiss Made Host</title>
				<meta name="description" content="We accept a wide variety of most common and easy-to-use payment methods including credit and debit cards, bank wire transfers, PayPal, crypto payments and various digital currencies." />
				<script type="application/ld+json">{JSON.stringify(richData)}</script>
			</Helmet>
			<div className="fullwidth pic payment-methods">
				<div className="row">
					<div className="twelve columns">
						<div className="transparent">
							<h1 className="title">Payment Methods <span>@ SWISSMADE.HOST</span></h1>
							<h2>100+ Convenient Ways to Make a Payment</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="fullwidth dark">
				<div className="row pagetitle">
					<div className="six columns">
						<h3>Payment Processors</h3>
						<p>We accept a wide range of popular and user-friendly payment methods, including VISA, MasterCard, American Express, and Discover card payments. Additionally, we support PayPal payments, Bank Wire transfers, QIWI, and WebMoney, providing our customers with a diverse selection of payment options to suit their preferences and requirements.</p>
					</div>
					<div className="six columns">
						<h3>Crypto Payments</h3>
						<p>We are pleased to offer a range of cryptocurrencies for your payment convenience, which includes popular options such as Bitcoin, Bitcoin Cash, Ethereum, Dash, Litecoin, Nextcoin, and Tether, among others. To maintain the highest level of privacy during transactions, we encourage our customers to send crypto payments directly.</p>
					</div>
				</div>
			</div>
			<ListOptions />
		</React.Fragment>
	);
}

export default PaymentMethods;